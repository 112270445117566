import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./App.css";
import Div100vh from "react-div-100vh";
import { isMobile } from "react-device-detect";
import { Img } from "react-image";
import Tilt from "react-parallax-tilt";

declare global {
  interface Window {
    analytics: any;
  }
}

const mainTracklist = [
  "First Person Shooter",
  "Like That",
  "Push Ups",
  "Taylor Made Freestyle",
  "Euphoria",
  "6:16 In LA",
  "Family Matters",
  "Meet The Grahams",
  "Not Like Us",
  "The Heart Part 6",
];

const vinylName = "Kendrick vs. Drake\nVinyl Set";
const vinylUrl = "https://buy.stripe.com/9AQeWh5MCayog6s8wx";
const maxTiltAngle = 15;

const bonusTracklist = ["7-minute Drill", "BBL Drizzy", "Champagne Moments"];

const otherInclusions = [
  "Digital Download",
  "Digital Booklet",
  "Exclusive Poster",
];

const otherShopItems = [
  {
    image: "/shopItems/1.png",
    name: "Certified P\nPoster",
    price: "$9.99",
    url: "https://buy.stripe.com/aEU8xT3EubCsf2o5kt",
  },
  {
    image: "/shopItems/2.png",
    name: "For All The Good Kids\nPoster",
    price: "$9.99",
    url: "https://buy.stripe.com/7sI9BX7UK8qg1bycMW",
  },
  {
    image: "/shopItems/3.png",
    name: "Euphoria\nPoster",
    price: "$9.99",
    url: "https://buy.stripe.com/eVaaG1ej8ayo6vSaEP",
  },
  {
    image: "/shopItems/4.png",
    name: "Millie\nPoster",
    price: "$9.99",
    url: "https://buy.stripe.com/00g01n6QG8qg6vScMY",
  },
  {
    image: "/shopItems/5.png",
    name: "The Heart Pt. 6\nPoster",
    price: "$9.99",
    url: "https://buy.stripe.com/6oE5lH4Iy6i85rOdR3",
  },
  {
    image: "/shopItems/8.png",
    name: "GKMC Van\nHot Wheels",
    price: "$39.99",
    url: "https://buy.stripe.com/14k3dz6QGfSIdYk6ow",
  },
  {
    image: "/shopItems/7.png",
    name: "Sticker\nPack",
    price: "$6.99",
    url: "https://buy.stripe.com/9AQdSdej8eOE07ueV9",
  },
  {
    image: "/shopItems/6.png",
    name: "New Ho King\nFortune Cookie",
    price: "$6.99",
    url: "https://buy.stripe.com/fZedSdej80XO2fCbIW",
  },
];

function ShopItemText({ name, price }: { name: string; price: string }) {
  return (
    <div className="flex flex-col text-center text-white gap-2 whitespace-pre">
      <div className="text-2xl font-bold" style={{ fontFamily: "silom" }}>
        {name}
      </div>
      <div className="text-2xl" style={{ fontFamily: "futura" }}>
        {price}
      </div>
    </div>
  );
}

function ShopItemSmallCard({
  image,
  name,
  price,
  url,
}: {
  image: string;
  name: string;
  price: string;
  url?: string;
}) {
  return (
    <a
      style={{ cursor: 'url("/select.png"), auto' }}
      href={url}
      onClick={() => {
        window.analytics.track("item-click", { itemUrl: url, itemName: name });
      }}
    >
      <div className="flex flex-col min-w-[300px] w-[20vw] justify-center items-center hover:bg-black/50 rounded-2xl p-5">
        <Tilt tiltMaxAngleX={maxTiltAngle} tiltMaxAngleY={maxTiltAngle}>
          <Img src={image} alt={name} loading="lazy" />
        </Tilt>
        <ShopItemText name={name} price={price} />
      </div>
    </a>
  );
}

function Hero({
  setLoading,
  scrollY,
}: {
  setLoading: (loading: boolean) => void;
  scrollY: number;
}) {
  const startingModalWidth = Math.min(window.innerWidth, window.innerHeight);
  const scrollWidthChangeMultiplier = isMobile ? 2 : 1;

  return (
    <div className="flex flex-col justify-center items-center w-screen h-screen">
      <div
        className="flex flex-col justify-center items-center relative"
        style={{
          width:
            // rendering negative width causes the element to be frozen at last positive width
            Math.max(
              startingModalWidth * (1 - scrollY * scrollWidthChangeMultiplier),
              0
            ).toString() + "px",
          // height: "100%",
        }}
      >
        <div
          onClick={() => {
            window.analytics.track("video-click");
          }}
        >
          <iframe
            className="absolute"
            style={{
              width: "42.5%",
              height: "31.25%",
              top: "45%",
              left: "22.75%",
            }}
            src="https://www.youtube.com/embed/pzXBV5TeI3c?si=CugBeTb9xcd-aKXv"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
        <Img
          src={"/header-combined-wide-upres-light-1:2.png"}
          alt="header-logo"
          style={{
            width: "100%",
          }}
          onLoad={() => setLoading(false)}
        />
      </div>
    </div>
  );
}

function Page({ setLoading }: { setLoading: (loading: boolean) => void }) {
  const [scrollY, setScrollY] = useState(0);
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const [emailText, setEmailText] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  useEffect(() => {
    if (scrollDivRef.current) {
      scrollDivRef.current.addEventListener("scroll", () => {
        if (scrollDivRef.current) {
          setScrollY(scrollDivRef.current.scrollTop / window.innerHeight);
        }
      });
    }
  }, [scrollDivRef]);

  useEffect(() => {}, [scrollY]);

  return (
    <div
      style={{
        backgroundImage: `url(${"/bg-1:2.png"})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "black",
        height: "100vh",
      }}
    >
      <div
        style={{
          overflow: "auto",
          height: "inherit",
        }}
        ref={scrollDivRef}
      >
        <Hero setLoading={setLoading} scrollY={scrollY} />

        <div className="flex flex-col justify-center gap-[5vh] w-full text-white mb-[5vh]">
          <div className="flex flex-col text-center gap-2">
            <div
              className="font-bold"
              style={{
                fontFamily: "silom",
                fontSize: Math.max(window.innerWidth / 40, 40),
              }}
            >
              Vinyl Includes
            </div>
            {mainTracklist.map((track, index) => (
              <div
                key={index}
                className="text-3xl"
                style={{
                  fontFamily: "futura",
                  fontSize: Math.max(window.innerWidth / 70, 15),
                  lineHeight: "1.5",
                }}
              >
                {track}
              </div>
            ))}
          </div>
          <div className="flex flex-col text-center gap-2">
            <div
              className="font-bold"
              style={{
                fontFamily: "silom",
                fontSize: Math.max(window.innerWidth / 60, 30),
              }}
            >
              Bonus Tracks
            </div>
            {bonusTracklist.map((track, index) => (
              <div
                key={index}
                style={{
                  fontFamily: "futura",
                  fontSize: Math.max(window.innerWidth / 70, 15),
                  lineHeight: "1.5",
                }}
              >
                {track}
              </div>
            ))}
          </div>
          <div className="flex flex-col text-center gap-2">
            <div
              className="font-bold"
              style={{
                fontFamily: "silom",
                fontSize: Math.max(window.innerWidth / 60, 30),
              }}
            >
              All Proceeds Support Toronto and LA Youth Charities!
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full justify-center items-center mb-[5vh]">
          <div
            className="flex h-[6vh] justify-center items-center gap-2"
            style={{
              width: "50%",
              minWidth: "300px",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <input
              className="flex-grow bg-white h-full rounded-lg text-center p-2"
              style={{
                fontFamily: "silom",
                outline: "none",
                fontSize: Math.max(window.innerWidth / 70, 15),
                width: isMobile ? "100%" : "auto",
              }}
              placeholder="Enter email for new projects!"
              onChange={(e) => setEmailText(e.target.value)}
              value={emailText || ""}
            />
            <button
              className="bg-white p-4 rounded-lg h-full hover:bg-gray-300"
              style={{
                fontFamily: "futura",
                width: isMobile ? "100%" : "auto",
              }}
              onClick={() => {
                window.analytics.track("email-sign-up", {
                  email: emailText,
                });
                setEmailText(null);
              }}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center gap-[5vh] p-10 mb-[5vh]">
          <a
            style={{ cursor: 'url("/select.png"), auto' }}
            href={vinylUrl}
            className="w-2/3 min-w-[300px] hover:bg-black/50 rounded-2xl p-5"
            onClick={() => {
              window.analytics.track("item-click", {
                itemUrl: vinylUrl,
                itemName: vinylName,
              });
            }}
          >
            <Tilt
              tiltMaxAngleX={maxTiltAngle / 2}
              tiltMaxAngleY={maxTiltAngle / 2}
            >
              <Img
                src={
                  window.innerWidth < 500
                    ? "/main-shop-image-mobile.png"
                    : "/main-shop-image.png"
                }
                alt={vinylName}
                loading="lazy"
              />
            </Tilt>
            <ShopItemText name={vinylName} price="$39.99" />
          </a>
          <div className="flex flex-wrap justify-center items-center gap-[0.5vw] w-2/3 min-w-[300px]">
            {otherShopItems.map((item, index) => (
              <ShopItemSmallCard
                key={index}
                image={item.image}
                name={item.name}
                price={item.price}
                url={item.url}
              />
            ))}
          </div>
        </div>
        <div
          className="text-white text-center mb-[5vh]"
          style={{
            fontFamily: "silom",
            fontSize: Math.max(window.innerWidth / 70, 15),
          }}
        >
          Contact: thebeefvinyl@gmail.com
        </div>
      </div>
    </div>
  );
}

function Loading() {
  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black z-50 text-white text-5xl"
      style={{ fontFamily: "Silom" }}
    >
      Loading...
    </div>
  );
}

function App() {
  const [imageLoading, setImageLoading] = useState(true);
  const [defaultLoading, setDefaultLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setDefaultLoading(false);
    }, 1000);
  }, []);

  // place loading layer above everything else
  return (
    <>
      {(imageLoading || defaultLoading) && <Loading />}
      <Page setLoading={setImageLoading} />
    </>
  );
}

export default App;
